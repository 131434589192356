/* eslint-disable import/prefer-default-export */

// Font list
export const InterFontList = [
  '"Inter"',
  '"Roboto"',
  '"Helvetica"',
  '"Arial"',
  "sans-serif",
].join(",");
