import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { createContext, useEffect, useMemo, useState } from "react";

interface SignalRContextType {
  connection: HubConnection | null;
}

export const SignalRContext = createContext<SignalRContextType>({
  connection: null,
});

interface SignalRProviderProps {
  children: React.ReactNode;
}

export const SignalRProvider: React.FC<SignalRProviderProps> = ({
  children,
}) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const token = process.env.REACT_APP_SIGNALR_TOKEN ?? "";
  const API_URL =
    process.env.GATSBY_SIGNALR_URL ?? "https://s-shock-campaign-dev.azurewebsites.net";

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${API_URL}/shock-hub`, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, [token]);

  const contextValue = useMemo(() => ({ connection }), [connection]);

  return (
    <SignalRContext.Provider value={contextValue}>
      {children}
    </SignalRContext.Provider>
  );
};
