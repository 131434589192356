export const GoogleGlid = "gclid";
export const FacebookFbclid = "fbclid";
export const GoogleSrc = "gclsrc";

export function removeClidId(clidType: string) {
  if (typeof window !== "undefined") {
    try {
      const storedGclid = JSON.parse(sessionStorage.getItem(clidType) || "{}");
      if (!storedGclid?.value) {
        return;
      }
      const isGclidValid =
        storedGclid && new Date().getTime() < storedGclid.expiryDate;

      if (!isGclidValid) {
        localStorage.removeItem(clidType);
      }
    } catch {
      localStorage.removeItem(clidType);
    }
  }
}

export function addTrackingFromStorageToUrl(url: string) {
  if (typeof window !== "undefined") {
    try {
      const gclid = localStorage.getItem(GoogleGlid);
      const fbclid = localStorage.getItem(FacebookFbclid);
      const urlObject = new URL(url);
      if (gclid) {
        const parsedGclid = JSON.parse(gclid);
        urlObject.searchParams.set(GoogleGlid, parsedGclid.value);
        urlObject.searchParams.set("expiryDate", parsedGclid.expiryDate);
      }

      if (fbclid) {
        urlObject.searchParams.set(FacebookFbclid, fbclid);
      }
      return urlObject.toString();
    } catch (e) {
      //
    }
  }
  return url;
}

export const getParam = (p: string, url: string): string | null => {
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getExpiryRecord = (value: string) => {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value,
    expiryDate,
  };
};
