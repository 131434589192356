exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acknowledgements-tsx": () => import("./../../../src/pages/acknowledgements.tsx" /* webpackChunkName: "component---src-pages-acknowledgements-tsx" */),
  "component---src-pages-ambassadorer-tsx": () => import("./../../../src/pages/ambassadorer.tsx" /* webpackChunkName: "component---src-pages-ambassadorer-tsx" */),
  "component---src-pages-ansvarlig-rapportering-tsx": () => import("./../../../src/pages/ansvarlig-rapportering.tsx" /* webpackChunkName: "component---src-pages-ansvarlig-rapportering-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-artikler-tsx": () => import("./../../../src/pages/artikler.tsx" /* webpackChunkName: "component---src-pages-artikler-tsx" */),
  "component---src-pages-gjeldfri-avgi-stot-stemme-tsx": () => import("./../../../src/pages/gjeldfri/avgi-stot-stemme.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-avgi-stot-stemme-tsx" */),
  "component---src-pages-gjeldfri-index-tsx": () => import("./../../../src/pages/gjeldfri/index.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-index-tsx" */),
  "component---src-pages-gjeldfri-julekalenderen-24-tsx": () => import("./../../../src/pages/gjeldfri/julekalenderen24.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-julekalenderen-24-tsx" */),
  "component---src-pages-gjeldfri-julekalenderen-24-vilkaar-tsx": () => import("./../../../src/pages/gjeldfri/julekalenderen24-vilkaar.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-julekalenderen-24-vilkaar-tsx" */),
  "component---src-pages-gjeldfri-stot-tsx": () => import("./../../../src/pages/gjeldfri/stot.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-stot-tsx" */),
  "component---src-pages-gjeldfri-stot-vilkaar-tsx": () => import("./../../../src/pages/gjeldfri/stot-vilkaar.tsx" /* webpackChunkName: "component---src-pages-gjeldfri-stot-vilkaar-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lan-forbrukslan-tsx": () => import("./../../../src/pages/lan/forbrukslan.tsx" /* webpackChunkName: "component---src-pages-lan-forbrukslan-tsx" */),
  "component---src-pages-lan-index-tsx": () => import("./../../../src/pages/lan/index.tsx" /* webpackChunkName: "component---src-pages-lan-index-tsx" */),
  "component---src-pages-lan-lan-status-tsx": () => import("./../../../src/pages/lan/lanStatus.tsx" /* webpackChunkName: "component---src-pages-lan-lan-status-tsx" */),
  "component---src-pages-lan-omstartslan-tsx": () => import("./../../../src/pages/lan/omstartslan.tsx" /* webpackChunkName: "component---src-pages-lan-omstartslan-tsx" */),
  "component---src-pages-lan-refinansiering-tsx": () => import("./../../../src/pages/lan/refinansiering.tsx" /* webpackChunkName: "component---src-pages-lan-refinansiering-tsx" */),
  "component---src-pages-okonomiamatorene-tsx": () => import("./../../../src/pages/Okonomiamatorene.tsx" /* webpackChunkName: "component---src-pages-okonomiamatorene-tsx" */),
  "component---src-pages-om-horde-tsx": () => import("./../../../src/pages/om-horde.tsx" /* webpackChunkName: "component---src-pages-om-horde-tsx" */),
  "component---src-pages-personvernerklaering-tsx": () => import("./../../../src/pages/personvernerklaering.tsx" /* webpackChunkName: "component---src-pages-personvernerklaering-tsx" */),
  "component---src-pages-responsible-disclosure-tsx": () => import("./../../../src/pages/responsible-disclosure.tsx" /* webpackChunkName: "component---src-pages-responsible-disclosure-tsx" */),
  "component---src-pages-rewards-tsx": () => import("./../../../src/pages/rewards.tsx" /* webpackChunkName: "component---src-pages-rewards-tsx" */),
  "component---src-pages-spoersmal-og-svar-tsx": () => import("./../../../src/pages/spoersmal-og-svar.tsx" /* webpackChunkName: "component---src-pages-spoersmal-og-svar-tsx" */),
  "component---src-pages-vilkaar-tsx": () => import("./../../../src/pages/vilkaar.tsx" /* webpackChunkName: "component---src-pages-vilkaar-tsx" */),
  "component---src-templates-blogg-post-tsx": () => import("./../../../src/templates/blogg-post.tsx" /* webpackChunkName: "component---src-templates-blogg-post-tsx" */),
  "component---src-templates-campaign-page-tsx": () => import("./../../../src/templates/campaign-page.tsx" /* webpackChunkName: "component---src-templates-campaign-page-tsx" */),
  "component---src-templates-campaign-toc-page-tsx": () => import("./../../../src/templates/campaign-toc-page.tsx" /* webpackChunkName: "component---src-templates-campaign-toc-page-tsx" */)
}

